import dnc_logo from "../assets/images/xcf_files/DNC_logo.png";
import vt_aoe from "../assets/images/xcf_files/vt_aoe_logo.png";
import vt_honors from "../assets/images/xcf_files/vt_honors_college2.png";
import premier_air from "../assets/images/xcf_files/premier_air_logo.png";

export default [
	{
		id: 4,
		name: "Virginia Polytechnic Institute and State University",
		designation: "Master's of Science in Aerospace Engineering",
		dated: "August 2020 - May 2022",
		logo: dnc_logo,
		companyLink: "https://www.aoe.vt.edu/graduate.html",
		description:
			"<p>Completing a M.S. in Aerospace Engineering at Virginia Tech involved taking graduate course work that focus in dynamics, controls, and estimation (DC&E). Further, a <a href=\"http://hdl.handle.net/10919/110850\">thesis</a> was completed under Dr. Craig Woolsey, who is well known in the field of DC&E throughout academia. The thesis focus on active dihedral applied to a traditionally fixed wing UAS. This thesis test my modeleding and simulation skills with application to LPV systems.</p>"
	},
	{
		id: 3,
		name: "Virginia Polytechnic Institute and State University",
		designation: "Bachelor's of Science in Aerospace Engineering",
		dated: "August 2016 - May 2020",
		logo: vt_aoe,
		companyLink: "https://www.aoe.vt.edu/",
		description:
			"<p>Completeing a B.S. in Aerospace Engineering at Virginia Tech has well prepared me for real world problems that occur daily in the field. The experience obtained in research labs, design teams, and interships has allowed me to acquire the knowledge needed to perform with excellence both in my current and future positions. The success I had during my undergraduate education can be credited to the time and effort put in, as well as the mentorship of professors who wanted their students to succeed Notable awards and achievements during my undergraduate education include: <ul><li>2020 AIAA Undergraduate Team Aircraft Design Competition 2nd Place Winner</li><li>Hume Scholar with Hume Center for National Security and Technology</li><li>Ralph and William Hodges Memorial Academic Scholarship</li></ul></p>"
	},
	{
		id: 2,
		name: "Virginia Tech Honors College",
		designation: "Honors Laureate Diploma",
		dated: "August 2017 - May 2020",
		logo: vt_honors,
		companyLink: "https://honorscollege.vt.edu/",
		description:
			"<p>Upon graduation from Virginia Tech, I was able to graduate with an Honors Laureate Diploma. To obtain this, thirty additional credit hours were required to prove that I could complete courses at higher standard both in and outside of the aerospace discipline. Additional information can be located on the Honors College's website.</p>"
	},
	{
		id: 1,
		name: "Premier Air Flight School",
		designation: "Private Pilot License",
		dated: "June 2016 - August 2017",
		logo: premier_air,
		companyLink: "http://www.premierair.net/",
		description:
			"<p>Out of my passion for flight, I decide to take on the challenge of obtaining a Private Pilot License during one of my summers working at home. This education and flight experience gave me the ability to recognize, from a pilot's perspective, what the desirable traits are for inside and outside of the cockpit.</p>"
	}
];
